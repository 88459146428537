import { Injectable } from '@angular/core';
import { EupHttpHandler } from '@core/eupHttpHandler.service';
import { EupRoutesService } from '@core/eupRoutes.service';
import { IKeyValue } from '@interfaces/IKeyValue';
import { TranslateService } from '@ngx-translate/core';
import { ContactType } from '@shared/enums';
import { PagedQueryResult, PagedQueryParameters, KeyValuePair } from '@shared/generalInterfaces';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/internal/operators';
import { CompanyInfoModel } from '../models/company-info.model';
import { DeleteRelationResponse } from '../models/delete-relation.model';
import { Invitation } from '../models/invitation.model';
import { InviteRequest } from '../models/Invite-request.model';
import { BusinessPartnerContactRelationship } from '../models/practice-user.model';

@Injectable()
export class AccountManagementService {

	private _practiceUsers: BusinessPartnerContactRelationship[] = [];
	public get practiceUsers(): BusinessPartnerContactRelationship[] {
		return this._practiceUsers;
	}

	constructor(private http: EupHttpHandler, 
		private eupRoutesService: EupRoutesService,
		private translateService: TranslateService
		) { }

	getCompanyInfo(companyId: number): Observable<CompanyInfoModel> {
		const url = this.eupRoutesService.accountManagement.getCompanyInfoUrl(companyId);
		return this.http.get(url, undefined, false);
	}

	getPracticeUsers(query: PagedQueryParameters): Observable<PagedQueryResult> {
		const url = this.eupRoutesService.accountManagement.getContactAccountRelations;
		return this.http.get(url, { params: query.createSearchParams() }, true, true).pipe(
			tap<PagedQueryResult>(res => this._practiceUsers = res.results)
		);
	}

	updatePracticeUserRelation(relation: BusinessPartnerContactRelationship): Observable<PagedQueryResult> {
		const url = this.eupRoutesService.accountManagement.updateContactAccountRelationUrl;
		return this.http.patch(url, relation, {}, true, true);
	}

	setCompanyLogo(businessPartnerId: number, logoImageBase64: string): Observable<any> {
		const body = { businessPartnerId, logoImageBase64 };
		const url = this.eupRoutesService.accountManagement.setCompanyLogoUrl();
		return this.http.patch(url, body);
	}

	deleteRelation(id: number, bpId: number): Observable<DeleteRelationResponse> {
		const url = this.eupRoutesService.accountManagement.deleteContactAccountRelationUrl(id, bpId);
		return this.http.delete(url, {}, true, true);
	}

	isUserExists(email: string): Observable<boolean> {
		return of(false);
	}

	buildFullAddress(
		addressLine1: string,
		addressLine2: string,
		addressLine3: string,
		city: string,
		stateCode: string,
		zip: string,
		countryCode: string
	): string {
		return [addressLine1, addressLine2, addressLine3, city, stateCode, zip, countryCode].filter((part) => !!part).join(', ');
	}

	getInvitations(query: PagedQueryParameters): Observable<PagedQueryResult> {
		const url = this.eupRoutesService.accountManagement.invitationsListUrl;
		return this.http.get(url, { params: query.createSearchParams() }, true, true);
	}

	saveInvitation(request: InviteRequest): Observable<Invitation> {
		const url = this.eupRoutesService.accountManagement.saveInvitationUrl;
		return this.http.post(url, request, {}, true, true);
	}

	deleteInvitation(id: string, companyId: number): Observable<boolean> {
		const url = this.eupRoutesService.accountManagement.deleteInvitationUrl(id, companyId);
		return this.http.delete(url, {}, true, true);
	}

	formatContactType(types: ContactType[]): string {
		return types.sort((c1, c2) => c1 > c2 ? 1 : -1).map(t => this.translateService.instant('ContactTypes.' + ContactType[t])).join(' + ');
	}

	isInvitationEmailExists(companyId: number, email: string): Observable<boolean> {
		return this.http.get(this.eupRoutesService.accountManagement.isInvitationEmailExists(companyId, email), {}, true, false);
	}

	isInvitationPhoneExists(companyId: number, phone: string): Observable<boolean> {
		return this.http.get(this.eupRoutesService.accountManagement.isInvitationPhoneExists(companyId, phone), {}, true, false);
	}

	contactHasRelations(email: string): Observable<boolean> {
		return this.http.get(this.eupRoutesService.accountManagement.contactHasRelations(email), {}, true, false);
	}
		
	IsContactExistInIds(salesforceContactId: string) {
		return this.http.get(this.eupRoutesService.accountManagement.isContactExistsInIds(salesforceContactId));
	}

	getContactsTypes(isLab: boolean): IKeyValue[]{
		return isLab ?
		[
			{
				key: this.translateService.instant('ContactTypes.ExtStaff_Lab'),
				value: [ContactType.ExtStaff_Lab]
			},
		] :
		[
			{
				key: this.translateService.instant('UserForm.Dentist'),
				value: [ContactType.Dentist]
			},
			{
				key: this.translateService.instant('UserForm.Orthodontist'),
				value: [ContactType.Orthodontist]
			},
			{
				key: this.translateService.instant('UserForm.DentistAndOrthodontist'),
				value: [ContactType.Dentist, ContactType.Orthodontist]
			},
			{
				key: this.translateService.instant('UserForm.OperationalOfficePersonnel'),
				value: [ContactType.Operational_Office_Personnel]
			},
			{
				key: this.translateService.instant('UserForm.ClinicalOfficePersonnel'),
				value: [ContactType.Clinical_Office_Personnel]
			}
		];
	}
}
