import { Component, OnInit, OnDestroy, ViewChild, Input, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { EupRoutesService } from '../../core/eupRoutes.service';
import { EupHttpHandler } from '../../core/eupHttpHandler.service';
import { RouterInterceptorService } from '../../core/routerInterceptor.service';
import { SettingsModal } from '../../settings/settings.component';
import { Utils } from '../utils.service';
import { GlobalSettingsService, ContextParams, GlobalSettings } from '../../core/globalSettings.service';
import { Company, Contact } from '../generalInterfaces';
import { GoogleAnalyticsService } from '../../core/googleAnalytics.service';
import { AccessibilityService } from '../accessibility.service';
import { MessagesService } from '../../messages/messages.service';
import { DownloadNotificationService } from '../downloadNotification/downloadNotification.service';
import { DownloadNotificationComponent } from '../downloadNotification/downloadNotification.component';
import { AuthService, OAuthResponse } from 'app/services/authentication/auth.service';
import { LocalStorageService } from '@core/localStorage.service';
import { Consts } from '@shared/consts';
import { SoftwareOptionsForCompany, FeatureToggle } from './../../shared/enums';
import { FeaturesToggleSettingsService } from 'app/featuresToggleSettings/service/featuresToggleSettings.service';
import { SoftwareOptionsService } from '@core/softwareOptions.service';
import { AppConfigService } from 'app/services/appConfig/appConfigService';
import { switchMap, tap } from 'rxjs/operators';
import { LoggerService } from '@logging/logger.service';

@Component({
	selector: 'eup-sticky-header',
	templateUrl: './stickyHeader.component.html',
	styleUrls: ['./stickyHeader.component.scss']
})
export class StickyHeaderComponent implements OnInit, OnDestroy {
	public static readonly homeImage = 'home46';
	public static readonly homeLink = 'home';
	private readonly backImage = 'backButton46';
	private rolePath: string;
	private storage: Storage;

	isLogoutOpen = false;
	isSettingsMenuOpen = false;
	isLinksMenuOpen = false;
	isDoctorsMenuOpen = false;
	isCompaniesMenuOpen = false;
	enablePracticeManagement = false;
	practiceManagementFeatureToggle = false;
	companies: Company[];
	filteredCompanies: Company[];
	doctors: Contact[];
	selectedCompany: Company;
	selectedDoctor: Contact;

	contextChangedSubscription: Subscription;
	getContactsSubscription: Subscription;
	homeLinkClickSubscription: Subscription;
	ftSubscription: Subscription;

	searchCompanyText = '';
	showCompaniesFiltering = false;

	@Input() titleText = '';
	@Input() image = StickyHeaderComponent.homeImage;
	@Input() backLink = StickyHeaderComponent.homeLink;
	@Input() backToLastPage = null;
	@Input() disableContactSelection = false;
	@Input() disableDoctorSelection = false;
	@Input() hideContactSelection = false;
	@Input() isLongTitle = false;
	@Input() uponHomeLinkClickCallback: Function;
	@Input() homeLinkClickEvent: EventEmitter<any>;
	@Input() disableSiteNavigation = 'false';
	@Input() isBackLinkAbsoluteUrl = 'false';
	@Input() shouldAddRoleToBackLink = 'true';
	@ViewChild(SettingsModal) settingsModal: SettingsModal;
	@ViewChild(DownloadNotificationComponent) downloadNotification: DownloadNotificationComponent;

	constructor(
		private router: Router,
		private http: EupHttpHandler,
		public globalSettingsService: GlobalSettingsService,
		private utils: Utils,
		private routerInterceptor: RouterInterceptorService,
		private googleAnalyticsService: GoogleAnalyticsService,
		private eupRoutesService: EupRoutesService,
		private route: ActivatedRoute,
		private accessibilityService: AccessibilityService,
		private downloadNotificationService: DownloadNotificationService,
		private authService: AuthService,
		private featuresToggleSettingsService: FeaturesToggleSettingsService,
		localStorageService: LocalStorageService,
		public softwareOptionsService: SoftwareOptionsService,
		private appConfigService: AppConfigService,
		private loggerService: LoggerService
	) {
		this.storage = localStorageService.storage;
	}

	get hasFullOrdersVisibility(): boolean {
		return this.settings.hasFullOrdersVisibility;
	}

	get username(): string {
		return this.settings.username;
	}

	get disableSiteNavigationFunc(): boolean {
		return this.disableSiteNavigation === 'true';
	}

	get isBackLinkAbsoluteUrlFunc(): boolean {
		return this.isBackLinkAbsoluteUrl === 'true';
	}

	get shouldAddRoleToBackLinkFunc(): boolean {
		return this.shouldAddRoleToBackLink === 'true';
	}

	get isCompanyDropdownDisabled(): boolean {
		return this.disableContactSelection || this.companies.length === 1;
	}

	get isDoctorDropdownDisabled(): boolean {
		return !this.hasFullOrdersVisibility || this.disableContactSelection || this.doctors.length === 1 || this.disableSiteNavigationFunc || this.disableDoctorSelection;
	}

	private get settings(): GlobalSettings {
		return this.globalSettingsService.get();
	}

	toggleLogoutMenu() {
		this.isLogoutOpen = !this.isLogoutOpen;
	}

	hideLogoutMenu() {
		this.isLogoutOpen = false;
	}

	toggleSettingsMenu() {
		this.isSettingsMenuOpen = !this.isSettingsMenuOpen;
	}

	hideSettingsMenu() {
		this.isSettingsMenuOpen = false;
	}

	toggleLinksMenu() {
		this.isLinksMenuOpen = !this.isLinksMenuOpen;
	}

	hideLinksMenu() {
		this.isLinksMenuOpen = false;
	}

	ngOnDestroy(): void {
		this.utils.clearObservablesSubscriptions(this);
	}

	hideDoctorMenu(): void {
		this.isDoctorsMenuOpen = false;
	}

	hideCompaniesMenu(): void {
		this.isCompaniesMenuOpen = false;
	}

	hideDoctorAndCompaniesMenu(): void {
		this.isDoctorsMenuOpen = false;
		this.isCompaniesMenuOpen = false;
	}

	companiesMenuToggled(isOpened: boolean): void {
		if (isOpened) {
			this.hideLinksMenu();
			this.hideLogoutMenu();
			this.downloadNotification.onClose();
		}
		this.clearCompaniesSearch(isOpened);
		this.isCompaniesMenuOpen = isOpened;
	}

	doctorsMenuToggled(isOpened: boolean): void {
		if (isOpened) {
			this.hideLinksMenu();
			this.hideLogoutMenu();
			this.downloadNotification.onClose();
		}

		this.isDoctorsMenuOpen = isOpened;
	}

	ngOnInit(): void {
		const selectedCompanyId = +this.settings.selectedCompanyId;
		this.companies = this.settings.companies;
		this.filteredCompanies = this.settings.companies;
		this.rolePath = this.globalSettingsService.rolePath();
		this.showCompaniesFiltering = this.companies.length >= this.appConfigService.appSettings.minimumCompaniesForFiltering;
		if (!this.isBackLinkAbsoluteUrlFunc && this.shouldAddRoleToBackLinkFunc) {
			this.backLink = this.rolePath + '/' + this.backLink;
		}
		this.selectedCompany = this.getCompanyById(selectedCompanyId);
		this.redirectIfRequired(this.selectedCompany);
		this.fillDoctors(selectedCompanyId);
		this.contextChangedSubscription = this.globalSettingsService.contextChanged.subscribe((params: ContextParams) => {
			if (!params.companyId || !params.doctorId) {
				return;
			}
			this.onCompanyChanged(this.getCompanyById(+params.companyId));
			this.onDoctorChanged(this.getDoctorById(+params.doctorId));
			this.enablePracticeManagement = this.shouldShowPracticeManagement();
		});
		this.ftSubscription = this.featuresToggleSettingsService.getFeaturesToggleSettings().subscribe(() => {
			this.practiceManagementFeatureToggle = this.featuresToggleSettingsService.isVisibleFeature(
				FeatureToggle.PracticeManagement
			);

			this.enablePracticeManagement = this.shouldShowPracticeManagement();
		});

		// when this property is set to true - override image and back link
		// according to previous url
		if (this.backToLastPage === 'true') {
			const previousRoute = this.routerInterceptor.previousRoute;
			// if no previous url exists (e.g. user entered url in a new tab)
			// or the previous url is home so back button should link to home page
			// or user was redirected to a page from straight from login
			if (
				!previousRoute ||
				previousRoute === '/' + this.rolePath + '/' + StickyHeaderComponent.homeLink ||
				previousRoute === '/login' ||
				previousRoute.substring(0, previousRoute.indexOf('?')) === '/login' ||
				previousRoute === '/'
			) {
				this.setDefaultLinkAndImage();
			} else {
				// a previous url exists and it is not the home page so back button should link
				// to the last page that the user was at
				this.backLink = previousRoute.slice(1);
				this.image = this.backImage;
			}
		} else if (this.backToLastPage === 'false') {
			this.setDefaultLinkAndImage();
		}

		this.preselectCompany();
	}

	logout(): void {
		this.http
			.post(this.eupRoutesService.home.logout)
			.pipe(
				switchMap(() => {
					this.downloadNotificationService.clear();
					if (this.featuresToggleSettingsService.isVisibleFeature(FeatureToggle.UseSctAuthorization)) {
						const sessionInfo = JSON.parse(this.storage.getItem(Consts.Storage.SessionInfo)) as OAuthResponse;
						return this.authService.invalidateSession(sessionInfo.sessionId).pipe(
							tap((res) => {
								if (!res) {
									this.loggerService.info('Failed to invalidate session', { module: 'StickyHeaderComponent' });
								}
							})
						);
					}
					return of({});
				})
			)
			.subscribe(() => {
				this.authService.logout();
			});
	}

	onCompanyChanged(company: Company): void {
		if (this.selectedCompany === company) {
			return;
		}

		this.redirectIfRequired(company);
		this.selectedCompany = company;
		this.globalSettingsService.setCompanyAndDefaultDoctor(company.id);
		this.globalSettingsService.setCompanySoftwareOptions(company.id);
		this.fillDoctors(company.id);
		this.googleAnalyticsService.hitEvent('Home', 'Change Company');
	}

	onDoctorChanged(doctor: Contact): void {
		if (this.selectedDoctor === doctor || doctor === undefined) {
			return;
		}

		this.selectedDoctor = doctor;
		this.globalSettingsService.setOnBehalfDoctor(this.selectedCompany.id, this.selectedDoctor.contactId).subscribe(_ => {
				this.globalSettingsService.setDoctor(doctor.contactId);
				this.googleAnalyticsService.hitEvent('Home', 'Change Doctor');			
			}
		);
	}

	onImageLinkClick(): void {
		if (this.uponHomeLinkClickCallback && this.homeLinkClickEvent) {
			if (!this.homeLinkClickSubscription) {
				this.homeLinkClickSubscription = this.homeLinkClickEvent.subscribe((res: any) => {
					this.router.navigateByUrl(`/${this.backLink.toLowerCase()}`);
				});
			}
			this.uponHomeLinkClickCallback();
		} else {
			if (this.isBackLinkAbsoluteUrlFunc) {
				window.location.href = this.backLink;
			} else {
				this.router.navigateByUrl(`/${this.backLink.toLowerCase()}`);
			}
		}
	}

	private getDoctorById(id: number): Contact {
		return this.doctors.filter((c: Contact) => c.contactId === id)[0];
	}

	private getCompanyById(id: number): Company {
		return this.settings.companies.filter((c: Company) => c.id === id)[0];
	}

	private fillDoctors(companyId: number): void {
		this.doctors = [
			{
				contactId: this.settings.contactId,
				contactName: this.settings.username
			}
		];

		this.selectedDoctor = this.doctors[0];

		if (this.hasFullOrdersVisibility) {
			this.getContactsSubscription = this.globalSettingsService.getContactsForCompany(companyId).subscribe((data: Contact[]) => {
				this.doctors = data;
				if (this.doctors.filter((c: Contact) => c.contactId === +this.settings.selectedDoctorId).length > 0) {
					this.selectedDoctor = this.getDoctorById(+this.settings.selectedDoctorId);
				}
			});
		}
	}

	private preselectCompany() {
		this.route.queryParams.subscribe((params) => {
			if (!params['companyid']) {
				return;
			}
			this.onCompanyChanged(this.getCompanyById(+params['companyid']));
		});
	}

	private redirectIfRequired(company: Company): void {
		if (!company.handlingEupUrl) {
			return;
		}
		if (company.handlingEupUrl.endsWith('/')) {
			company.handlingEupUrl = company.handlingEupUrl.slice(0, -1);
		}

		const myIteroBffUrl = new URL(this.eupRoutesService.serverUrl);
		const companyMyIteroBffUrl = new URL(company.handlingEupUrl);
		const currentPath = this.accessibilityService.getPathName();
		let currentSearch = this.accessibilityService.getCurrentSearch();

		let url = `${this.accessibilityService.getLocation().origin}/login?returnUrl=${currentPath}`;

		if (this.shouldConcatinateCompany(currentPath)) {
			const matches = currentSearch.match(/companyid=([^&]*)/);
			if (matches && matches.length > 0) {
				currentSearch = currentSearch.replace(matches[0], `companyid=${company.id}`);
			} else {
				currentSearch += `?companyId=${company.id}`;
			}
		}

		url += escape(currentSearch);
		const myiteroComUrl = new URL(url);

		const allowRedirection = myIteroBffUrl.host !== companyMyIteroBffUrl.host;
		if (allowRedirection) {
			this.logout();
			this.storage[Consts.Storage.RegionalApiEndpoint] = `${companyMyIteroBffUrl.origin}/`;
			this.accessibilityService.redirectTo(myiteroComUrl.href);
			return;
		}
	}

	private setDefaultLinkAndImage() {
		this.backLink = this.rolePath + '/' + StickyHeaderComponent.homeLink;
		this.image = StickyHeaderComponent.homeImage;
	}

	private shouldConcatinateCompany(urlPathName: string): boolean {
		if (urlPathName.indexOf('rx/viewer') > -1) {
			return false;
		} else {
			return true;
		}
	}

	private shouldShowPracticeManagement() {
		const softwareOptions = this.globalSettingsService.get().companySoftwareOptions;
		const practiceManagementSoftwareOptions = [SoftwareOptionsForCompany.shouldEnableVisitReportMidc,
			SoftwareOptionsForCompany.shouldEnableVisitReportScanner,
			SoftwareOptionsForCompany.AccountManagement
		];
		const res = this.softwareOptionsService.areSoftwareOptionExist(practiceManagementSoftwareOptions, softwareOptions);

		return this.practiceManagementFeatureToggle && res;
	}

	private searchClicked(event: Event){
		event.stopPropagation();
	}

	private onSearchCompany(event: Event){
		if(this.searchCompanyText.length > 0){
			this.filteredCompanies = this.settings.companies.filter(c => c.name.toLowerCase()
			.includes(this.searchCompanyText.toLowerCase()));
		} else{
			this.filteredCompanies = this.companies;
		}
	}

	private clearCompaniesSearch(isDropdwonOpen: boolean) {
		this.searchCompanyText = !isDropdwonOpen ? '' : this.searchCompanyText;
		this.filteredCompanies = !isDropdwonOpen ? this.companies : this.filteredCompanies;
	}
}
