export class Consts {
    public static Storage = {
        Settings: 'settings',
        Routes: 'routes',
        RegionalApiEndpoint: 'regionalApiEndpoint',
        ServerVersion: 'serverVersion',
        RxEnabledData: 'rxEnabledData',
        SessionInfo: 'sessionInfo'
    };

    public static TablesIds = {
        PracticeUsers: 'practice-users-table',
        PracticeInvitations: 'practice-invitations-table'
    }

    public static GoogleApiAddressTypes = {
        Locality: 'locality',
        PostalCode: 'postal_code',
        Country: 'country',
        AdministrativeAreaLevel1: 'administrative_area_level_1'
    }
}