import { Injectable } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';
import { HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpHandler } from '@angular/common/http';
import { HttpEvent } from '@angular/common/http';
import { OAuthResponse } from '../authentication/auth.service';
import { Consts } from '@shared/consts';
import {  GlobalSettings } from '../../core/globalSettings.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
	constructor() {
	}
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		
		const sessionInfo =localStorage.getItem(Consts.Storage.SessionInfo);
		const settings = JSON.parse(localStorage.getItem(Consts.Storage.Settings)) as GlobalSettings;

		if (sessionInfo) {
			let headers = new HttpHeaders();
			const OAuth = JSON.parse(sessionInfo ) as OAuthResponse;
			if (OAuth.accessToken && OAuth.accessToken.trim() !== '') {
				headers = headers.set('Authorization', `Bearer ${OAuth.accessToken}`); 
			}
			if (settings) {
				if(settings.selectedCompanyId && settings.selectedCompanyId>0){
					headers=headers.set('X-Company-ID', settings.selectedCompanyId.toString());
				}
				if(settings.selectedDoctorId && settings.selectedDoctorId>0){
					headers=headers.set('X-Contact-ID', settings.selectedDoctorId.toString());
				}
			}
			const headersReq = req.clone({ headers });
			return next.handle(headersReq);
		}
		return next.handle(req);
  	}
}