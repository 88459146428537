import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SettingsModule } from '../settings/settings.module';
import { SharedAnonymousModule } from './shared.anonymous.module';
import { TrimPipe } from './trim.pipe';
import { EupDatePipe } from './eupDate.pipe';
import { ReceivedDatePipe } from './receivedDate.pipe';
import { EupDefaultText } from './eupDefaultText.pipe';
import { OrderByPipe } from './orderBy.pipe';
import { SafeHtmlPipe } from './safeHtml.pipe';
import { TableComponent } from './table/table.component';
import { SortableHeaderColumn } from './sortableHeaderColumn/sortableHeaderColumn.component';
import { DownloadLinkModal } from './downloadLinkModal/downloadLinkModal.component';
import { ChangeCaseTypeModal } from './changeCaseType/changeCaseType.component';
import { ChangeCaseTypeService } from './changeCaseType/changeCaseType.service';
import { ClickOutsideDirective } from './clickOutside.directive';
import { StickyHeaderComponent } from './stickyHeader/stickyHeader.component';
import { LabStickyHeaderComponent } from './labStickyHeader/labStickyHeader.component';
import { OrthoCadExportModal } from './orthoCadExport/orthoCadExport.component';
import { RestorativeExportModal } from './restorativeExport/restorativeExport.component';
import { TableFocusElementDirective } from './tableFocusElement.directive';
import { ShowButtonsRowDirective } from './showButtonsRow.directive';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AccessibilityService } from './accessibility.service';
import { MessagesService } from '../messages/messages.service';
import { DownloadNotificationComponent } from './downloadNotification/downloadNotification.component';
import { ExportDownloadsProgressListComponent } from './exportDownloadsProgressList/exportDownloadsProgressList.component';
import { HighlightTextPipe } from './highlightText.pipe';
import { DieOrdersComponent } from './dieOrders/dieOrders.component';
import { IteroNoteComponent } from './iteroNote/iteroNote.component';
import { IteroOrdersNotesComponent } from './iteroOrdersNotes/iteroOrdersNotes.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LabExportModal } from './labExport/labExport.component';
import { WindowsAppButtonComponent } from './windowsAppButton/windowsAppButton.component';
import { PatientAppService } from './PatientApp/patientApp.service';
import { PatientAppModalComponent } from './PatientApp/patientAppModal/patientApp.component';
import { TimerDirective } from './timer/timer.directive';
import { InactivityTimerDirective } from './inactivity-timer/inactivity-timer.directive';
import { ActivityMonitorDirective } from './inactivity-timer/activity-monitor.directive';
import { FeaturesToggleDirective } from '../featuresToggleSettings/directive/featuresToggle.directive';
import { GenericUIModule } from '@shared/genericUI/module/generic-ui/generic-ui.module';
import { IOSimPlusButtonComponent } from './iosim-plus/iosim-plus-button/iosim-plus-button.component';
import { AllowedCaseTypesDirective } from './allowedCaseTypes.directive';
import { VisitReportComponent } from 'app/visit-report/visit-report.component';
import { AccountManagementService } from '../practiceManagement/services/account-management.service';
import { SafeUrlPipe } from './safeUrl.pipe';
import { ReferralMaskDirective } from './referralMask/referral.mask.directive';
import { StatusInfoModalComponent } from '@shared/iosim-plus/iosim-plus-button/status-info-modal/status-info-modal.component';
import { TernaryPipe } from './ternary.pipe';
import { MapperPipe } from './mapper.pipe';
import { PrintRxAppComponent } from 'app/rx/print-rx/print-rx-app.component';
import { DateOfBirthPipe } from '@shared/date-of-birth.pipe';
import { PracticeInformationBlockComponent } from 'app/practiceManagement/components/info/practice-information-block/practice-information-block.component';

const sharedComponents = [
	OrderByPipe,
	TrimPipe,
	TableComponent,
	SortableHeaderColumn,
	DownloadLinkModal,
	LabExportModal,
	EupDatePipe,
	EupDefaultText,
	ReceivedDatePipe,
	ChangeCaseTypeModal,
	TableFocusElementDirective,
	ShowButtonsRowDirective,
	OrthoCadExportModal,
	ClickOutsideDirective,
	StickyHeaderComponent,
	LabStickyHeaderComponent,
	DieOrdersComponent,
	RestorativeExportModal,
	SafeHtmlPipe,
	DownloadNotificationComponent,
	ExportDownloadsProgressListComponent,
	HighlightTextPipe,
	IteroNoteComponent,
	IteroOrdersNotesComponent,
	LabExportModal,
	WindowsAppButtonComponent,
	TimerDirective,
	InactivityTimerDirective,
	ActivityMonitorDirective,
	PatientAppModalComponent,
	FeaturesToggleDirective,
	IOSimPlusButtonComponent,
	AllowedCaseTypesDirective,
	VisitReportComponent,
	SafeUrlPipe,
	ReferralMaskDirective,
	StatusInfoModalComponent,
	TernaryPipe,
	MapperPipe,
	PrintRxAppComponent,
	DateOfBirthPipe,
	PracticeInformationBlockComponent
];

@NgModule({
    imports: [SharedAnonymousModule, SettingsModule, InfiniteScrollModule, FontAwesomeModule, GenericUIModule],
    declarations: [...sharedComponents],
    exports: [SharedAnonymousModule, GenericUIModule, ...sharedComponents],
    providers: [
        ChangeCaseTypeService,
        AccessibilityService,
        MessagesService,
        PatientAppService,
        AccountManagementService,
        EupDatePipe,
        TernaryPipe,
        MapperPipe
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}
