import { Injectable, OnDestroy } from '@angular/core';
import { BaseDestroyable } from '@core/base-destroyable';
import { GlobalSettingsService } from '@core/globalSettings.service';
import { LoggerService } from '@logging/logger.service';
import { WindowsApplications } from '@shared/windowsAppButton/WindowsApplications';
import { AccountManagementService } from 'app/practiceManagement/services/account-management.service';
import { WindowsApplicationStore } from 'app/settings/state/windows-application.store';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class WindowsApplicationService extends BaseDestroyable implements OnDestroy {

	constructor(
		private accountManagementService: AccountManagementService,
		private globalSettingsService: GlobalSettingsService,
		private store: WindowsApplicationStore,
		private loggerService: LoggerService
	) {
		super();
		this.globalSettingsService.contextChanged
			.pipe(
				map((context) => context.companyId),
				filter((companyId) => !!companyId),
				distinctUntilChanged(),
				mergeMap((companyId) => this.setApplications(companyId)),
				tap(_ => this.setPromptLogin(true)),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	setApplicationsIfNeed(): Observable<any> {
		if(this.store.getValue().areApplicationsSet){
			return of();
		}
		return this.setApplications(this.globalSettingsService.get().selectedCompanyId);
	}

	setPromptLogin(value: boolean){
		this.store.update(currentValue => ({
			...currentValue, promptLogin: value
		}));
	}

	private setApplications(currentCompanyId: number): Observable<any> {
		return this.accountManagementService.getCompanyInfo(currentCompanyId).pipe(
			tap((response) => {
				const applications = [
					WindowsApplications.Iosim,
					WindowsApplications.IosimPa,
					WindowsApplications.IosimCa,
					WindowsApplications.IosimGo,
					WindowsApplications.IosimPro,
					WindowsApplications.Orthocad,
				];

				this.loggerService.debug(`Applications set for company ${currentCompanyId}`, {
					module: WindowsApplicationService.name,
				});

				this.store.update(() => ({
					applications,
					areApplicationsSet: true,
				}));
			})
		);
	}
}
