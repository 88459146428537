import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { EupRoutesService } from '../../core/eupRoutes.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalSettings, GlobalSettingsService } from '../../core/globalSettings.service';
import { EupHttpHandler } from '../../core/eupHttpHandler.service';
import { ContactWithBusinessPartners } from '../../shared/generalInterfaces';
import { Router, Params } from '@angular/router';
import { AuthenticationStatus } from './models/authentication-status';
import { LogoutParameters } from './models/logout-parameters';
import { IOSimSimulationInfoStore } from '../iosim-simulation.store/iosim-simulation-status-progress.store';
import { AppConfigService } from 'app/services/appConfig/appConfigService';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private appId = 'midc';
	private iTeroAuthEndpointBase = `${this.appConfigService.appSettings.iTeroAuthEndpoint}` + `/api/v1/auth/`;
	private startSessionUrl = `${this.iTeroAuthEndpointBase}session/start`;
	private invalidateSessionUrl = (sessionId: string) => `${this.iTeroAuthEndpointBase}session/invalidate?sessionId=${sessionId}&appId=${this.appId}`;

	authStatus$: BehaviorSubject<AuthenticationStatus>;

	constructor(private http: EupHttpHandler,
				private eupRoutesService: EupRoutesService,
				private globalSettingsService: GlobalSettingsService,
				private router: Router,
				private simulationStatusProgressStore: IOSimSimulationInfoStore,
				private appConfigService: AppConfigService) {
					this.authStatus$ = new BehaviorSubject<AuthenticationStatus>({ isAuthenticated: this.isAuthenticated() });
				}
			
	loginWithOAuth(credentials: UserCredentials): Observable<OAuthResponse> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});

		return this.http
			.post(this.startSessionUrl, { username: credentials.username, password: credentials.password, appId: this.appId }, { headers, observe: 'response' })
			.pipe(
				map((res: any) => {
					return res.body;
				})
			);
	}

	login(user: UserCredentials): Observable<GlobalSettings> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		const auth = () =>
			this.http
				.post(
					this.eupRoutesService.login.url,
					{ username: user.username, password: user.password },
					{ headers, observe: 'response' }
				)
				.pipe(
					map((res: any) => {
						if (res.hasOwnProperty('body') && Object.keys(res.body).length > 0) {
							this.authStatus$.next({ isAuthenticated: true });
							return res.body as GlobalSettings;
						} else {
							// in case we want to redirect the user to another route, we need to get an error in order to make unsubscribe the login subscription
							return res.json();
						}
					})
				);
		return this.eupRoutesService.doAfterInit(auth);
	}

	invalidateSession(sessionId: string): Observable<boolean> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});

		return this.http
			.post(this.invalidateSessionUrl(sessionId), null, { headers, observe: 'response' })
			.pipe(
				map((res: any) => {
					return res.body;
				})
			);
	}

	impersonate(user: ImpersonateUserCredentials): Observable<GlobalSettings> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});

		const auth = () => this.http.post(
			this.eupRoutesService.logonAs.url,
			{ contactId: user.contactId, businessPartnerId: user.businessPartnerId },
			{ headers, observe: 'response' }
		)
		.pipe(
			map((res: any) => {
				if (res.hasOwnProperty('body') && Object.keys(res.body).length > 0) {
					this.authStatus$.next({ isAuthenticated: true });
					return res.body as GlobalSettings;
				} else {
					// in case we want to redirect the user to another route, we need to get an error in order to make unsubscribe the login subscription
					return res.json();
				}
			})
		);

		return this.eupRoutesService.doAfterInit(auth);
	}

	getContactWithBusinessPartners(userName: string): Observable<ContactWithBusinessPartners[]> {
		const params = new HttpParams().set('contactName', userName);

		return this.http.get(this.eupRoutesService.logonAs.getContacts, { params: params }, null, false);
	}

	getDetails(): Observable<LoginDetails> {
		const getLogin = () => this.http.get(this.eupRoutesService.getLoginData(), undefined, undefined, false);
		return this.eupRoutesService.doAfterInit(getLogin, true);
	}

	isAuthenticated(): boolean {
		const settings = this.globalSettingsService.get();
		return !!settings;
	}

	logout(params: LogoutParameters = {}): void {
		const queryParams: Params = { returnUrl: params.returnUrl };
		this.router.navigate(['/login'], { queryParams });
		this.globalSettingsService.clear();
		this.authStatus$.next(
			{ 
			 isAuthenticated: false,
			 isUnauthenticatedByTimeout: params.byTimeout,
			});
		this.simulationStatusProgressStore.reset();
	}
}

export class UserCredentials {
	username: string;
	password: string;
	applicationId = "midc";

	constructor(username: string, password: string) {
		this.username = username;
		this.password = password;
	}
}

export class OAuthResponse {
	sessionId: string;
	accessToken: string;
}

export class ImpersonateUserCredentials {
	contactId: number;
	businessPartnerId: number;

	constructor(contactId: number, businessPartnerId: number) {
		this.businessPartnerId = businessPartnerId;
		this.contactId = contactId;
	}
}

export class LoginDetails {
	appVersion: string;
	bffVersion: string;
	databaseVersion: string;
	serverIP: string;
	forgotPasswordUrl: string;
	dbInfo: string;
	region: string;
}
