import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { BaseDestroyable } from '@core/base-destroyable';
import { GlobalSettingsService } from '@core/globalSettings.service';
import { LocalStorageService } from '@core/localStorage.service';
import { LoggerService } from '@logging/logger.service';
import { TranslateService } from '@ngx-translate/core';
import { PrintFormat, RoleTypeEnum, RxAppApplicationModeEnum } from '@shared/enums';
import { AppConfigService } from 'app/services/appConfig/appConfigService';
import { Observable, BehaviorSubject, of, defer } from 'rxjs';
import { tap, takeUntil, withLatestFrom, catchError, filter, switchMap, shareReplay, take, mapTo } from 'rxjs/operators';
import { RxAppInputParameters } from '../models/rx-app-input-parameters';
import { RxAppSdkService } from '../services/rx-app-sdk.service';
import { RxService } from '../services/rx.service';
import { Consts } from '@shared/consts';

@Component({
	selector: 'eup-print-rx-app',
	templateUrl: './print-rx-app.component.html',
	styleUrls: ['./print-rx-app.component.scss']
})
export class PrintRxAppComponent extends BaseDestroyable implements OnChanges, AfterViewInit, OnDestroy {
	@Input() isRxEditable: boolean;
	@ViewChild('rxAppPrintContainer') rxAppPrintContainer: ElementRef<HTMLElement>;
	private isRxEditable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private readonly componentName = 'PrintRxAppComponent';
	private storage: Storage;
	rxIdsForPrint$: Observable<number[]> = this.rxService.rxIdsForPrintObservable.asObservable();

	printWindow: Window;

	constructor(
		private sdkService: RxAppSdkService,
		private globalSettingsService: GlobalSettingsService,
		private translateService: TranslateService,
		private appConfigService: AppConfigService,
		private logger: LoggerService,
		private rxService: RxService,
		private localStorageService: LocalStorageService

	) {
		super();

		this.storage = localStorageService.storage;
	}

	ngOnChanges(changes: SimpleChanges): void {
		const isRxEditable = changes.isRxEditable?.currentValue;
		if (typeof isRxEditable === 'boolean') {
			this.isRxEditable$.next(isRxEditable);
		}
	}

	ngAfterViewInit(): void {
		const loadSdkAndCreateRxApp$: Observable<boolean> = defer(() => this.loadSdkAndCreateRxApp()).pipe(
			mapTo(true),
			take(1),
			shareReplay({ bufferSize: 1, refCount: true }),
			catchError(() => of(false))
		);
		this.rxIdsForPrint$.pipe(
			filter(rxIdsForPrint => rxIdsForPrint?.length > 0),
			switchMap(() => loadSdkAndCreateRxApp$),
			filter(isLoaded => isLoaded),
			switchMap(() => this.sdkService.printRxsReset()),
			withLatestFrom(this.rxIdsForPrint$),
			switchMap(([, rxIdsForPrint]) => {
				this.sdkService.updateInputParameters({ rxIdsForPrint });
				return this.printRx({ rxIdsForPrint });
			}),
			takeUntil(this.componentAlive$)).subscribe();
	}

	ngOnDestroy(): void {
		this.sdkService.deleteRxApp();
		super.ngOnDestroy();
		this.rxService.rxIdsForPrintObservable.next([]);
	}

	printRx({ rxIdsForPrint }: { rxIdsForPrint: number[] }): Observable<any> {
		return this.sdkService.printRxs()
			.pipe(
				tap(() => {
					this.logger.info(`Rx(s) Printed`, { module: this.componentName, extendedParameters: { rxIdsForPrint: rxIdsForPrint.join(',') } });
				}),
				catchError(error => {
					this.logger.error('Print Rx(s) has failed:', { error, module: this.componentName });
					return of(error);
				}),
			);
	}

	private loadSdkAndCreateRxApp(): Observable<any> {
		return this.sdkService.loadSdk(this.rxAppPrintContainer.nativeElement)
			.pipe(
				switchMap(_ => this.sdkService.loadSdkConfiguration()),
				tap(() => {
					this.setStaticInputParameters();
					this.sdkService.createRxApp(this.rxAppPrintContainer.nativeElement);
				})
			);
	}

	private setStaticInputParameters(): void {
		const orientation = this.globalSettingsService.getPrintformat() === PrintFormat.Horizontal ? 'landscape' : 'portrait';
		const { selectedCompanyId: companyId, selectedDoctorId } = this.globalSettingsService.get();
		const { apiEndpoint } = this.appConfigService.appSettings;
		const regionalApiEndpoint = this.storage[Consts.Storage.RegionalApiEndpoint];
		const languageCode = this.translateService.currentLang;
		const inputParameters: RxAppInputParameters = {
			contactId: selectedDoctorId,
			companyId,
			languageCode,
			apiEndpoint: regionalApiEndpoint || apiEndpoint,
			applicationMode: RxAppApplicationModeEnum.Web,
			userRole: RoleTypeEnum.Lab,
			orientation,
			isHeadlessPrint: true
		};
		this.sdkService.updateInputParameters(inputParameters);
	}
}
