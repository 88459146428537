import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { concatMapTo, map, tap, take, shareReplay, filter, switchMapTo } from 'rxjs/operators';
import { FeatureToggleSettings } from '../featuresToggleSettings.model';
import { EupRoutesService } from '@core/eupRoutes.service';
import { EupHttpHandler } from '@core/eupHttpHandler.service';
import { FeatureToggleStore } from '../state/feature-toggle-store';
import { FeatureToggleQuery } from '../state/feature-toggle-query';

@Injectable()
export class FeaturesToggleSettingsService implements OnDestroy {
	public featuresToggleSettings$: Observable<FeatureToggleSettings[]> = this.featureToggleQuery.featureToggles$;
	public featuresToggleSettings: FeatureToggleSettings[] = this.featureToggleQuery.featureToggles;
	private routerSubscription: Subscription;
	private ftRequestSubscription: Subscription;

	private _refreshFtSettings$ = new BehaviorSubject<void>(null);

	public refreshFeatureToggleSettings() {
		this._refreshFtSettings$.next();
	}

	private featuresToggleSettingsRequestObs$: Observable<FeatureToggleSettings[]> = this.http
		.getWithoutCancel(this.eupRoutesService.getFeaturesToggleSettingsEndpoint(), {}, true, true)
		.pipe(
			take(1),
			tap((res: FeatureToggleSettings[]) => {
				this.featureToggleStore.update({ featureToggles: [...res], lastUpdateTimestamp: new Date(), isNavigationTriggered: false });
			})
		);

	private featuresToggleSettingsRequest$: Observable<FeatureToggleSettings[]> = this._refreshFtSettings$.pipe(
		switchMapTo(this.featuresToggleSettingsRequestObs$),
		shareReplay(1)
	);

	private postFeatureToggleSettingsRequest(featuresToggleSettings: FeatureToggleSettings[]): Observable<FeatureToggleSettings[]> {
		return this.http.post(this.eupRoutesService.featuresToggleSettings.updateFeaturesToggleSettings, featuresToggleSettings);
	}

	constructor(
		private http: EupHttpHandler,
		private eupRoutesService: EupRoutesService,
		private featureToggleStore: FeatureToggleStore,
		private featureToggleQuery: FeatureToggleQuery
	) {
		this.routerSubscription = this.featureToggleQuery.isNavigationTriggered$
			.pipe(
				filter((isNavigationTriggered) => isNavigationTriggered === true),
				tap(() => {
					const { intervalTimespan, lastUpdateTimestamp } = this.featureToggleQuery;
					const now = new Date();
					const timeDifference = this.getTimeDifference({ date1: now, date2: lastUpdateTimestamp });
					const shouldTriggerRequest = !lastUpdateTimestamp || timeDifference > intervalTimespan;
					if (shouldTriggerRequest) {
						this.refreshFeatureToggleSettings();
						this.ftRequestSubscription = this.featuresToggleSettingsRequest$.subscribe();
					}
					this.featureToggleStore.update({ isNavigationTriggered: false });
				})
			)
			.subscribe();
	}

	ngOnDestroy(): void {
		this.routerSubscription.unsubscribe();
		this.ftRequestSubscription.unsubscribe();
	}

	getFeaturesToggleSettings(): Observable<FeatureToggleSettings[]> {
		return this.featuresToggleSettings$;
	}

	private getTimeDifference({ date1, date2 }: { date1: Date; date2: Date }) {
		if (date2 === null) {
			return null;
		}

		const utc1 = Date.UTC(
			date1.getFullYear(),
			date1.getMonth(),
			date1.getDate(),
			date1.getHours(),
			date1.getMinutes(),
			date1.getSeconds(),
			date1.getMilliseconds()
		);
		const utc2 = Date.UTC(
			date2.getFullYear(),
			date2.getMonth(),
			date2.getDate(),
			date2.getHours(),
			date2.getMinutes(),
			date2.getSeconds(),
			date2.getMilliseconds()
		);
		return Math.floor(utc1 - utc2);
	}

	updateFeaturesToggleSettings(featureToggleSettings: FeatureToggleSettings[]): Observable<FeatureToggleSettings[]> {
		this.featureToggleStore.update({ featureToggles: [...featureToggleSettings] });
		return this.postFeatureToggleSettingsRequest(featureToggleSettings).pipe(concatMapTo(this.featuresToggleSettingsRequest$));
	}

	isVisibleFeature(featureId: string, defaultValue = true): boolean {
		const { featureToggles: featuresToggleSettings } = this.featureToggleQuery;
		const selectedFeature: FeatureToggleSettings = featuresToggleSettings.find(
			(feature: FeatureToggleSettings) => feature.id === featureId
		);

		return selectedFeature ? selectedFeature.isActive : defaultValue;
	}

	isVisibleFeature$(featureId: string): Observable<boolean> {
		return this.getFeaturesToggleSettings().pipe(map(() => this.isVisibleFeature(featureId, false)));
	}
}
